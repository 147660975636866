import { defineComponent, computed } from '@vue/composition-api';
import { StoreKey } from '@/symbols';
import { injectStrict } from '@/lib/misc';
import AuthMenu from '@/components/AuthMenu.vue';
export default defineComponent({
    components: {
        AuthMenu
    },
    setup() {
        const store = injectStrict(StoreKey);
        const externalData = (url) => computed(() => {
            const user = store.getters['auth/user'];
            return `${url}?id=${user?.sub}`;
        });
        return { externalData };
    }
});
